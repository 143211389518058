<template>
    <div>
        <div class="page-header hidden-sm-and-down">
            <div style="float:right">
                <el-button size="mini" @click="onAdd('')" icon="el-icon-plus" type="primary">新建记录</el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="专家库"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="专业">
                    <el-select v-model="search.zhuanye" placeholder="请选择专业" style="width:100px" clearable >
                        <el-option
                                v-for="item in selectList.zyList"
                                :key="item.label"
                                :label="item.label"
                                :value="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="职称">
                    <el-select v-model="search.zhicheng" placeholder="请选择职称" style="width:100px" clearable >
                        <el-option
                                v-for="item in selectList.zcList"
                                :key="item.label"
                                :label="item.label"
                                :value="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学历">
                    <el-select v-model="search.xueli" placeholder="请选择学历" style="width:100px" clearable >
                        <el-option
                                v-for="item in selectList.xlList"
                                :key="item.label"
                                :label="item.label"
                                :value="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="search.name" placeholder="请输入姓名" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-row :gutter="10">
            <el-col :span="6" v-for="(item,index) in table.data" :key="index">
                <el-card shadow="hover" style="margin-bottom: 10px">
                    <div class="expert-card" @click="onAdd(item.id)">
                        <el-image class="img"
                                  :src="item.imageUrl">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <div class="content">
                            <div>姓名：{{item.name}}</div>
                            <div>专业：{{item.zhuanye}}</div>
                            <div>职称：{{item.zhicheng}}</div>
                            <div>学历：{{item.xueli}}</div>
                            <div>简介：{{item.jieshao}}</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <div class="pager">
            <div class="pager" v-if="table.total > table.pageSize">
                <el-pagination layout="total, prev, pager, next, sizes"
                               :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                               :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                               @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                search: {},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                selectList: {
                    xlList: [],
                    zcList: [],
                    zyList: []
                }
            }
        },
        methods: {
            onAdd(id) {
                this.$router.push({
                    path: `/main/expert/form/${id}`,
                })
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                const resp = await this.$http.get(`/expert/table?page=${this.table.page}&pageSize=${this.table.pageSize}&status=1`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    console.log(resp.data)
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            },
            async getSelect() {
                try {
                    const resp = await this.$http.get(`/expert/select`)
                    if (!resp.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.selectList = resp.data
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            }
        },
        mounted() {
            this.getSelect()
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .search {
        margin: 15px 0;
        padding: 10px;
        background-color: #FAFAFA;
        border: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .expert-card {
        display: flex;
        cursor: pointer;

        .img {
            width: 120px;
            height: 160px;
            text-align: center;
        }

        .content {
            flex: 1;
            padding: 0 10px;
        }
    }

    .pager {
        margin: 20px;
        text-align: center;
    }
</style>
